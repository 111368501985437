require('popper.js')
require('bootstrap')

document.getElementById('sidebarToggle').addEventListener('click', toggleSidebar);
document.getElementById('sidebarToggleText').addEventListener('click', toggleSidebar);
document.getElementById('closeSidebar').addEventListener('click', closeSidebar);

function toggleSidebar() {
    // Cambia la clase "active" del sidebar al hacer clic en el botón o en el texto "MENU"
    document.querySelector('.sidebar-sticky').classList.toggle('active');
}

function closeSidebar() {
    // Cierra el sidebar al hacer clic en el botón de cierre
    document.querySelector('.sidebar-sticky').classList.remove('active');
}